<template>
    <div>
        <div class="m-form m-form--fit m--margin-bottom-20 mb-5">
            <div class="row mb-5">
                <div class="form-group col-md-6 mt-2 mb-2">
                    <label>{{$t('material_list.main_inventory')}}</label>
                    <multiselect v-model="inventory"
                                 :placeholder="$t('material_list.main_inventory')"
                                 label="name"
                                 track-by="id"
                                 :options="inventories"
                                 :multiple="false"
                                 :taggable="false"
                                 :show-labels="false"
                                 :show-no-options="false"
                                 :show-no-results="false">
                    </multiselect>
                </div>
                <div class="form-group col-md-6 mt-2 mb-2">
                    <label>{{$t('material_list.sub_inventory')}}</label>
                    <multiselect v-model="sub_inventory"
                                 :placeholder="$t('material_list.sub_inventory')"
                                 label="name"
                                 track-by="id"
                                 :options="inventories"
                                 :multiple="true"
                                 :taggable="false"
                                 :show-labels="false"
                                 :show-no-options="false"
                                 :show-no-results="false">
                    </multiselect>
                </div>
                <div class="form-group col-md-6 mt-2 mb-2">
                    <label>{{$t('material_list.quantity')}}</label>
                    <input type="number" min="1" v-model="filters.quantity" class="form-control">
                </div>

                <div class="form-group d-inline-flex col-md-6 mt-6 mb-0">
                    <button type="button" @click="doFilter" class="btn btn-primary mt-auto mr-2" id="m_search">
                            <span><i class="fas fa-search"></i>
                                <span>{{$t('search')}}</span>
                            </span>
                    </button>

                </div>
            </div>
        </div>
        <div class="table-responsive">
            <table class="table">
                <thead>
                <tr>
                    <th>{{$t('material_list.item')}}</th>
                    <th>{{$t('material_list.origin_quantity')}}</th>
                    <th>{{$t('material_list.supposed')}}</th>
                    <th>{{$t('material_list.reserved')}}</th>
                    <th>{{$t('material_list.inventory')}}</th>
                    <th>{{$t('material_list.available_quantity')}}</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(row, index) in dataList" :key="index">
                    <td>{{row.item_name}}</td>
                    <td>{{row.origin_quantity}}</td>
                    <td>{{row.supposed}}</td>
                    <td>{{row.reserved}}</td>
                    <td>{{row.inventory_name}}</td>
                    <td>{{row.available_quantity}}</td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
    import ApiService from "@/core/services/api.service";

    export default {
        name: "validateInventory",
        props: {
            innerId: null,
        },
        data() {
            return {
                mainRoute: 'manufacturers/material',
                mainRouteDependency: 'base/dependency',

                filters: {
                    inventory_id: null,
                    sub_inventory_id: null,
                    quantity: 1,
                },
                dataList: [],
                inventories: [],
                inventory: null,
                sub_inventory: [],
            }
        },
        watch: {
            inventory: function (val) {
                if (val) {
                    this.filters.inventory_id = val.id;
                }
            },
            sub_inventory: function (val) {
                this.filters.sub_inventory_id = [];
                if (val && val.length > 0) {
                    val.forEach((row) => {
                        this.filters.sub_inventory_id.push(row.id);
                    });

                }
            },
        },

        methods: {

            getData() {
                ApiService.get(`${this.mainRoute}/statistics_inventory`,
                    {
                        params: {
                            inventory_id: this.filters.inventory_id,
                            sub_inventory_id: this.filters.sub_inventory_id,
                            id: this.innerId,
                            quantity: this.filters.quantity
                        }
                    }).then((response) => {
                    this.dataList = response.data.data;
                });
            },
            doFilter() {
                this.getData();
            },
            getInventories() {
                ApiService.get(this.mainRouteDependency + "/inventories").then((response) => {
                    this.inventories = response.data.data;
                });
            },
        },
        mounted() {
            this.getInventories();
        },
    }
</script>

<style scoped>

</style>